import {
  Button,
  Flex,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorMode,
} from "@chakra-ui/react";
import axios from "axios";
import type {
  GetStaticProps as GSP,
  InferGetStaticPropsType,
  NextPage,
} from "next";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import DashboardNav from "../components/DashboardNav";
import MainGraph from "../components/MainGraph";
import {
  currentUserDataState,
  graphDataState,
  isEmployeeState,
  isLoggedInState,
  totalGraphDataState,
} from "../recoilStates";
import styles from "../styles/Home.module.css";

const Home: NextPage = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [graphData, setGraphData] = useRecoilState(graphDataState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [currentUserData, setCurrentUserData] =
    useRecoilState(currentUserDataState);

  const [isEmployee, setIsEmployee] = useRecoilState(isEmployeeState);
  const router = useRouter();
  useEffect(() => {
    if (!isLoggedIn) {
      router.push("/sign-in");
    } else {
      // setTotalGraphData(dashboardData);
      if (isEmployee) {
        router.replace(`/register?businessId=${currentUserData.PersonId}`);
      } else
        router.replace(
          `/dashboard/${currentUserData.GUID}/?businessId=${currentUserData.PersonId}`
        );
    }
  }, [isLoggedIn, currentUserData, router]);

  return <Flex></Flex>;
};

export default Home;
